import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

let config = {}

export const setupFirebase = () => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'fe-checkout.firebaseapp.com',
      databaseURL: 'https://fe-checkout.firebaseio.com/',
      projectId: 'fe-checkout',
      storageBucket: 'fe-checkout.appspot.com',
      messagingSenderId: '160593637565',
      appId: '',
      measurementId: '',
    }
  } else {
    console.log('[development] -> firebase')
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'fe-checkout-dev.firebaseapp.com',
      databaseURL: 'https://fe-checkout-dev.firebaseio.com/',
      projectId: 'fe-checkout-dev',
      storageBucket: 'fe-checkout-dev.appspot.com',
      messagingSenderId: '286334464387',
      appId: '',
      measurementId: '',
    }
  }

  firebase.initializeApp(config)
  firebase.auth().signInAnonymously()

  return firebase
}

export default firebase
