import React from 'react'
import { TableFallback } from '~/components/v2/TableV2/components'
import { InputFieldV3, ButtonV3 } from '@provi/provi-components'
import { Button } from '~/components/v3'
import { InputButtonsWrapper, ListCoursesGrid, PagesText, PaginationContainer } from './styles'
import { useListCourses } from './hooks'
import { SearchIcon, PlusIcon } from '~/assets/svgs/v2'
import { EachCourse } from './components/EachCourse'
import { CoursesBreadCrumbs, CoursesModal } from '..'
import { shareLinkModal } from '~/screens/courses/constants'
import { copyString } from '~/helpers/copyAndPaste.js'
import { ArrowLeft, ArrowRight } from 'assets/svgs/v2'

const CoursesNotFoundComponent = () => (
  <>
    <TableFallback
      text={
        <>
          <strong>Não houve resultados para sua busca</strong>
          <br />
          Tente novamente ou limpe o resultado.
        </>
      }
    />
  </>
)

const FallbackComponent = ({ goToPageProp }) => (
  <>
    <TableFallback
      text={
        <>
          <strong>Você ainda não tem cursos listados</strong>
          <br />
          Adicione seu primeiro curso agora:
        </>
      }
    />
    <Button
      icon={<PlusIcon color={'white'} />}
      className={'addBtn'}
      text="Adicionar"
      mobileWidth={'100%'}
      onClick={() => goToPageProp('cursos/novo-curso')}
    />
  </>
)

function renderCoursesComponents(arrayCourseData, updateCourse, isMobile, goToPage) {
  return arrayCourseData.map((course) => (
    <EachCourse
      goToPageProp={goToPage}
      key={course.id}
      courseData={course}
      isMobile={isMobile}
      updateCourseProp={updateCourse}
    />
  ))
}

function renderConditionalCourses(coursesList, updateCourse, isMobile, goToPage) {
  return renderCoursesComponents(coursesList, updateCourse, isMobile, goToPage)
}

export const ListCourses = ({ showNotification }) => {
  const {
    goToPage,
    isMobile,
    coursesList,
    updateCourse,
    handleSearchCourseByName,
    filteredCourses,
    isLoading,
    shareLink,
    shareLinkModalRef,
    openLinkModal,
    closeLinkModal,
    page,
    pageInfo,
    nextPage,
    prevPage,
    handleSearchButton,
    handleClearSearchInput,
    wasSearchButtonUsed,
  } = useListCourses()

  return (
    <>
      <CoursesBreadCrumbs showNotification={showNotification} openLinkModal={openLinkModal} />
      {isLoading ? null : (
        <ListCoursesGrid>
          {coursesList.length ? (
            <>
              <div className={'searchBar'}>
                <InputFieldV3
                  onChange={handleSearchCourseByName}
                  type={'text'}
                  placeholder={'Pesquisar curso'}
                  mobileWidth="100%"
                  icon={<SearchIcon />}
                />
                <InputButtonsWrapper>
                  <ButtonV3 text="Pesquisar" width="49%" onClick={handleSearchButton} />
                  <ButtonV3 text="Limpar" width="49%" onClick={handleClearSearchInput} />
                </InputButtonsWrapper>
              </div>
              {renderConditionalCourses(coursesList, updateCourse, isMobile, goToPage)}
              <PaginationContainer>
                {page !== 1 && <ArrowLeft onClick={prevPage} />}
                <PagesText>{`Página ${page} de ${pageInfo.totalPages}`}</PagesText>
                {page !== pageInfo.totalPages && <ArrowRight onClick={nextPage} />}
              </PaginationContainer>
            </>
          ) : !coursesList.length && wasSearchButtonUsed ? (
            <>
              <div className={'searchBar'}>
                <InputFieldV3
                  onChange={handleSearchCourseByName}
                  type={'text'}
                  placeholder={'Pesquisar curso'}
                  mobileWidth="100%"
                  icon={<SearchIcon />}
                />
                <InputButtonsWrapper>
                  <ButtonV3 text="Pesquisar" width="49%" onClick={handleSearchButton} />
                  <ButtonV3 text="Limpar" width="49%" onClick={handleClearSearchInput} />
                </InputButtonsWrapper>
              </div>
              <CoursesNotFoundComponent />
            </>
          ) : (
            <FallbackComponent goToPageProp={goToPage} />
          )}
        </ListCoursesGrid>
      )}
      {isMobile && <ButtonV3 text="Novo curso" onClick={() => goToPage('cursos/novo-curso')} mobileWidth={'100%'} />}
      <CoursesModal
        modalRef={shareLinkModalRef}
        closeModalFn={closeLinkModal}
        modalObject={shareLinkModal}
        handlePrimaryBtn={() => copyString(shareLink?.link, 'link', closeLinkModal)}
        handleSecondaryBtn={() => copyString(shareLinkModal.onlyStrings(shareLink), 'linkAndText', closeLinkModal)}
        paragraphVariables={shareLink}
        headerText={'Compartilhe seu link geral de vendas'}
      />
    </>
  )
}
