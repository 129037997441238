import { useContext, useEffect, useState, useRef } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { getStorage } from 'utils'
import { getCoursesListService, updateCourseService } from 'services/ms-checkout/v4'

export const useListCourses = () => {
  const partnerId = getStorage({ key: 'partner-id' })
  const { isMobile, goToPage, setIsLoading, isLoading } = useContext(TemplateContext)
  const [coursesList, setCoursesList] = useState([])
  const [shareLink, setShareLink] = useState()
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({
    itemsPerPage: null,
    nextPage: null,
    previousPage: null,
    totalItems: null,
    totalPages: null,
  })
  const [coursesInput, setCoursesInput] = useState('')
  const [wasSearchButtonUsed, setWasSearchButtonUsed] = useState(false)
  const shareLinkModalRef = useRef()

  const nextPage = () => {
    setCoursesInput('')
    setPage((prev) => prev + 1)
  }

  const prevPage = () => {
    setCoursesInput('')
    setPage((prev) => prev - 1)
  }

  async function getCoursesList() {
    const resultList = await getCoursesListService({ partnerId, setIsLoading, page, relatedCourses: coursesInput })

    setCoursesInput('')
    setPageInfo(resultList.paging)
    setCoursesList(resultList.content)
    setShareLink(resultList.metadata && resultList.metadata.redirect_url)
  }

  const handleSearchButton = () => {
    setWasSearchButtonUsed(true)
    setPage(1)
    getCoursesList()
  }

  const handleClearSearchInput = () => {
    setWasSearchButtonUsed(false)
    setCoursesInput('')
    setPage(1)
    getCoursesList()
  }

  async function updateCourse({ id, body }) {
    await updateCourseService({ id, partnerId, body, setIsLoading })
    await getCoursesList()
  }

  async function handleSearchCourseByName(e) {
    setCoursesInput(e.target.value)
  }

  const openLinkModal = async () => {
    shareLinkModalRef.current.openModalFn()
  }

  const closeLinkModal = () => {
    shareLinkModalRef.current.closeModalFn()
  }

  useEffect(() => {
    getCoursesList()
  }, [partnerId, page])

  return {
    isMobile,
    goToPage,
    coursesList,
    getCoursesList,
    updateCourse,
    handleSearchCourseByName,
    isLoading,
    shareLink,
    shareLinkModalRef,
    openLinkModal,
    closeLinkModal,
    page,
    pageInfo,
    nextPage,
    prevPage,
    handleClearSearchInput,
    handleSearchButton,
    wasSearchButtonUsed,
  }
}
