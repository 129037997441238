import React from 'react'
import { IntegrationsHome, AddAccount, ConnectProducts, ManageAccount, Memberkit } from '~/screens/integrations/components'

const sharedIntegrationHomeObject = { breadCrumbTitle: 'Integrações', component: <IntegrationsHome />, key: 1 }

export const memberkitFlowArray = [
  { ...sharedIntegrationHomeObject },
  { breadCrumbTitle: 'Memberkit', component: <Memberkit />, key: 2 },
  { breadCrumbTitle: 'Adicionar conta', component: <AddAccount />, key: 3 },
  { breadCrumbTitle: 'Gerenciar conta', component: <ManageAccount />, key: 4 },
  { breadCrumbTitle: 'Conectar produtos', component: <ConnectProducts />, key: 5 },
]

export const productModalObj = {
  header: 'Desconectar produtos',
  firstParagraph: 'O vínculo entre o produto Provi e o produto MemberKit será desfeito.',
  secondParagraph: 'Deseja desconectar mesmo assim?',
  primaryBtnText: 'Sim, desconectar produtos',
}

export const accountModalObj = {
  header: 'Desvincular conta',
  firstParagraph:
    'Todo o fluxo automático para a liberação e o bloqueio de acesso ao conteúdo pelos alunos será interrompido e esses processos precisarão ser feitos de forma manual.',
  secondParagraph: 'Deseja desvincular a conta mesmo assim?',
  primaryBtnText: 'Sim, desvincular conta',
}
