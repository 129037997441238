import React from 'react'
import { ButtonV3 } from '@provi/provi-components'

import { CloseIcon } from '~/assets/svgs/v2'
import { StyledPortalModal, ModalContainer, FlexContainer, BlueHeader, BlackParagraph, ReversableFlexContainer } from './styles'

export const IntegrationsModal = ({ modalRef, closeModalFn, modalObject, handleUnlinkAccount, handleUnlinkProduct }) => {
  const { header, firstParagraph, secondParagraph, primaryBtnText } = modalObject || {}
  return (
    <StyledPortalModal ref={modalRef} backdropColor={'rgba(212, 218, 247, 0.8)'}>
      <ModalContainer>
        <FlexContainer>
          <BlueHeader>{header}</BlueHeader>
          <CloseIcon onClick={closeModalFn} />
        </FlexContainer>
        <BlackParagraph>{firstParagraph}</BlackParagraph>
        <BlackParagraph>{secondParagraph}</BlackParagraph>
        <ReversableFlexContainer>
          <ButtonV3 text="Cancelar" color={'white'} mobileWidth={'100%'} onClick={closeModalFn} />
          <ButtonV3 text={primaryBtnText} mobileWidth={'100%'} onClick={handleUnlinkProduct || handleUnlinkAccount} />
        </ReversableFlexContainer>
      </ModalContainer>
    </StyledPortalModal>
  )
}
