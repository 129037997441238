import { formatValue } from 'utils'

export const simulationsCopyText = (simulations) => {
  const handleWithOptions = (eachOption, index, startFrom = 0) => {
    const { installmentsToApply, monthlyInterest, pricePerInstallment } = eachOption
    const formattedOption = index + 1 + startFrom
    const formattedValue = formatValue(pricePerInstallment, true)
    const formattedMonthlyInterest = monthlyInterest ? `(${monthlyInterest}% a.m.)` : '(sem juros)'

    return `Opção ${formattedOption}: Entrada + ${installmentsToApply}x de ${formattedValue} ${formattedMonthlyInterest}\n`
  }

  const options =
    simulations?.options?.length && simulations.options.map((option, index) => handleWithOptions(option, index)).join('')
  const extraOptions =
    simulations?.extraOptions?.length &&
    simulations.extraOptions
      .map((extraOption, index) => handleWithOptions(extraOption, index, simulations?.options?.length))
      .join('')

  let output = ''

  output += `Valor da venda: ${formatValue(simulations?.values?.finalTotal, true)}\n`
  output += `Entrada: ${formatValue(simulations?.values?.upfrontAmount, true)}\n`
  output += `Parcelado: ${formatValue(simulations?.values?.installmentAmount, true)}\n\n`
  output += options
  if (simulations?.extraOptions?.length) {
    output +=
      '\nAs condições de parcelamento a seguir estão disponíveis para crédito educacional antecipado e estarão disponíveis apenas para os estudantes aprovados pelo motor de crédito.\n\n'
    output += extraOptions
  }

  return output
}
