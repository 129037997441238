import { api } from 'services/api'
import {
  IDeleteCourseInterfaceParams,
  IGetCourseInterfaceParams,
  IGetCourseResponse,
  IGetCoursesListResponse,
  IGetInterfaceParams,
  IUpdateCourseInterfaceParams,
  IIsV3ProvipayService,
} from './@types'
import { toast } from 'react-toastify'
import { customizeUrl } from 'utils'

export async function getCoursesListService({ partnerId, setIsLoading, page, relatedCourses }: IGetInterfaceParams) {
  setIsLoading(true)

  try {
    customizeUrl('delete', 'relatedCourses')

    if (relatedCourses) {
      const search = encodeURIComponent(relatedCourses)

      const result = await api.get<IGetCoursesListResponse>(
        `/v4/courses?PartnerId=${partnerId}&page=${page}&relatedCourses=${search}&listAll=true&showObsolete=false`,
      )

      customizeUrl('add', 'relatedCourses', search)

      return result.data
    }

    const result = await api.get<IGetCoursesListResponse>(
      `/v4/courses?PartnerId=${partnerId}&page=${page}&showObsolete=false&itemsPerPage=50`,
    )

    return result.data
  } catch (error: any) {
    toast(error)
  } finally {
    setIsLoading(false)
  }
}

export async function getCourseService({ id, partnerId, setIsLoading }: IGetCourseInterfaceParams) {
  setIsLoading(true)

  try {
    const result = await api.get<IGetCourseResponse>(`/v4/courses/${id}?PartnerId=${partnerId}`)

    return { ...result.data }
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}

export async function updateCourseService({ id, partnerId, body, setIsLoading }: IUpdateCourseInterfaceParams) {
  setIsLoading(true)
  try {
    await api.put(`/v4/courses/${id}?PartnerId=${partnerId}`, body)
    toast('Mudança feita!')
    return true
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}

export async function deleteCourseService({ id, partnerId, setIsLoading, goToPage }: IDeleteCourseInterfaceParams) {
  setIsLoading(true)
  try {
    await api.delete(`/v4/courses/${id}?PartnerId=${partnerId}`)
    toast('Curso deletado!')
    goToPage('cursos')
    return true
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}

export async function isV3ProvipayService({ setIsLoading }: IIsV3ProvipayService) {
  setIsLoading(true)
  try {
    const response = await api.get('/v4/provipay/version')

    return response.data.content.useV3
  } catch (error: any) {
    toast(error.response.data.error.message || error.response.message || 'Houve um erro, por favor tente mais tarde!')
  } finally {
    setIsLoading(false)
  }
}
