import React from 'react'
import { ButtonV3 } from '@provi/provi-components'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { accountModalObj } from '~/screens/integrations/constants'
import { useManageAccount } from './hooks'
import { ConnectIcon, PlusIcon } from '~/assets/svgs/v2/index'
import { EachIntegration, ActionsBtn } from '../index'
import { IntegrationsTable, IntegrationsModal } from './components'
import { TableFallback } from '~/components/v2/TableV2/components'
import { ManageAccountContainer } from './styles'

export const ManageAccount = () => {
  const {
    isMobile,
    modalRef,
    openModal,
    closeModal,
    memberkitConnections,
    isLoadingConnections,
    connectionsFilters,
    isLoadingFilters,
    page,
    nextPage,
    previousPage,
    filters,
    setFilters,
    currentIntegrationName,
    handleConnectProducts,
    handleUnlinkAccount,
    formatBackendResponse,
  } = useManageAccount()
  return (
    <>
      <ManageAccountContainer>
        <EachIntegration
          name={currentIntegrationName}
          noBorderBottomCondition={true}
          {...(!isMobile && {
            secondaryComponent: (
              <>
                <ActionsBtn
                  handleClick={handleConnectProducts}
                  svg={<PlusIcon bluePrimary />}
                  actionText={'Conectar produtos'}
                />
                <ActionsBtn handleClick={openModal} svg={<ConnectIcon bluePrimary />} actionText={'Desvincular conta'} />
              </>
            ),
          })}
        />
        <LoadingSkeleton
          isLoading={isLoadingConnections || isLoadingFilters}
          hasHeading
          hasParagraph
          containerHeight={'150px'}
          repeat={isMobile ? 1 : 3}
        >
          {memberkitConnections?.connectionsList?.length ? (
            <>
              <IntegrationsTable
                tableProps={{
                  memberkitConnections,
                  connectionsFilters,
                  page,
                  nextPage,
                  previousPage,
                  filters,
                  setFilters,
                  formatBackendResponse,
                }}
              />
            </>
          ) : (
            <TableFallback text={'Você ainda não possui produtos conectados'} />
          )}
        </LoadingSkeleton>
        {isMobile && (
          <>
            <ButtonV3 text="Conectar produtos" mobileWidth={'100%'} onClick={handleConnectProducts} />
            <ButtonV3 text="Desvincular conta" mobileWidth={'100%'} color={'white'} onClick={openModal} />
          </>
        )}
      </ManageAccountContainer>
      <IntegrationsModal
        modalRef={modalRef}
        closeModalFn={closeModal}
        modalObject={accountModalObj}
        handleUnlinkAccount={handleUnlinkAccount}
      />
    </>
  )
}
