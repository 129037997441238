import { useMemo } from 'react'

const useFinancingConditionModal = ({ options, extraOptions }) => {
  const maxInstallment = useMemo(() => {
    const allOptions = [...options, ...extraOptions]

    if (!allOptions.length) return

    const sortedOptions = allOptions.sort((a, b) => a?.installmentsToApply - b?.installmentsToApply)

    const lastOption = sortedOptions[sortedOptions.length - 1]

    return lastOption?.installmentsToApply
  }, [options, extraOptions])

  return { maxInstallment }
}

export default useFinancingConditionModal
