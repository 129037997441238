import React from 'react'
import { TemplateContext } from 'components/Template/context'
import { CoursesContext } from '~/screens/courses/context'

const optionsSelectable = [
  { label: 'Ver todas', value: 'all' },
  { label: 'Invísivel', value: 'invisible' },
  { label: 'Visível', value: 'visible' },
]

function useSwiperSectionHooks({ courseKey, courseClassesData }) {
  const { goToPage } = React.useContext(TemplateContext)
  const { setCourseClassCopy } = React.useContext(CoursesContext)
  const swiperRef = React.useRef(null)
  const [selectedOption, setSelectedOption] = React.useState({ label: 'Ver todas', value: 'all' })
  const courseClassesFiltered = React.useMemo(() => {
    switch (selectedOption.value) {
      case 'visible':
        return courseClassesData.filter((courseClass) => courseClass.visible)
      case 'invisible':
        return courseClassesData.filter((courseClass) => !courseClass.visible)
      case 'all':
      default:
        return courseClassesData
    }
  }, [courseClassesData, selectedOption])

  async function handleSelectClassroom(id) {
    goToPage(`cursos/${courseKey}/${id}`)
  }

  return {
    optionsSelectable,
    goToPage,
    swiperRef,
    selectedOption,
    setSelectedOption,
    courseClassesFiltered,
    handleSelectClassroom,
    setCourseClassCopy,
  }
}

export { useSwiperSectionHooks }
